import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
import moment from "moment/moment";
import momentTz from "moment-timezone";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import "./index.css";
import { BsGoogle, BsApple, BsMicrosoft } from "react-icons/bs";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CookieConsent from "react-cookie-consent";
import Countdown from "react-countdown";
import WebcastingContext from "../context/webcasting/webcastingContext";

// HOOKS & HELPERS
import useLogo from "../hooks/useLogo";
import {
  handleGenerateZoomCalendarLink,
  styleReleaseDate,
  titleCase,
} from "../helpers";

// Components
import HoldingVideo from "../components/players/video/HoldingVideo";
import { Menu, MenuItem } from "@material-ui/core";
import { CalendarToday, CalendarViewDayRounded } from "@material-ui/icons";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const BackgroundImg = lazy(() => import("../components/UI/BackgroundImg"));
const Logo = lazy(() => import("../components/UI/Logo"));
const ShareButtons = lazy(() => import("../components/UI/ShareButtons"));

const ZoomEvent = (props) => {

  const webcastingContext = useContext(WebcastingContext)

  const {setGA, authorization} = webcastingContext

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "90%",
      maxWidth: "600px",
      display: "inline-block",
      border: "3px solid orange",
    },
    // opacity: {
    //   backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
    // },
    mainContent: {
      padding: "8px 24px",
      marginBottom: "1.5rem",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0.85rem",
      },
    },
    gridItemWidth: {
      width: "100%",
    },
    actionArea: {
      flexDirection: "column",
      padding: "0px 24px 8px",
      [theme.breakpoints.down("400")]: {
        padding: "0px",
      },
    },
    date: {
      margin: "1.7rem 0px",
      color: "var(--clr-g-7e)",
      [theme.breakpoints.down("xs")]: {
        margin: "1.7rem 0px",
      },
      "& span": {
        fontSize: "1rem",
      },
    },
    btnsContainer: {
      width: "100%",
      display: props.match.location.pathname.includes("649ec23ed907fa0b867b55e6")? 'flex' : (props.match.location.pathname.includes("649ec23ed907fa0b867b55e6")? 'none' : 'flex'),
      justifyContent: "space-around",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: "1rem",
      [theme.breakpoints.down("650")]: {
        textAlign: "center",
        "& a": {
          marginBottom: "2rem",
        },
      },
      [theme.breakpoints.down("400")]: {
        padding: "0px",
      },
    },
    btnLink: {
      color: "#fff",
      backgroundColor: "#333",
      "&:hover": {
        backgroundColor: "#333",
      },
    },
    menuItemWithIcons:{
      display: 'flex', justifyContent: 'center', alignContent: 'center', gap: '0.4rem', alignItems:'center'
    },
    countdown: {
      marginTop: '0.5rem',
      marginLeft: "0px !important",
      marginBottom: "1.5rem",
      "& h1": {
        fontSize: "28px",
        color: "#333",
      },
      "& p": {
        fontSize: "0.9rem",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "2rem",
        "& h1": {
          fontSize: "28px",
        },
      },
    },
  }));


  useEffect(() => {
    console.log("propscccc", props);
  }, []);

  const baseUrl = process.env.REACT_APP_WEBCASTING_PLATFORM_URL;

  const [authUrl, setAuthUrl] = useState("");
  const [shareBtns, setShareBtns] = useState(false);
  const [eventID, setEventID] = useState("");
  const [title, setTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [eventStartTime, setEventStartTime] = useState(null)
  const [eventEndTime, setEventEndTime] = useState(null)

  const [brandingFound, setBrandingFound] = useState(false);
  const [logoPath, logoPadding] = useLogo(props.loading, props.event);
  const [caledarEvent, setCalendarEvent] = useState({});

  const [bgOpacity, setBgOpacity] = useState(false);
  const [newDateFormat, setNewDateFromat] = useState(null);
  const [timeZone, setTimeZone] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickCalendar = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMeetingConnect = ()=>{
        window.open(authorization?.registrants[0]?.zoom.join_url, "_blank");
  }
  useEffect(() => {
    setAuthUrl(props.match.match.url);
    setShareBtns(props.event.items[0].webcast.shareButtons);
    setEventID(props.event.items[0]._id);
    setTitle(props.event.items[0].title);
    setEventDescription(props.event.items[0].description);
    setCompanyName(titleCase(props.event.items[0].companies[0].name));
    if (props.event.items[0].webcast.holdingCardTransparency) {
      setBgOpacity(props.event.items[0].webcast.holdingCardTransparency);
    }
    setNewDateFromat(
      styleReleaseDate(
        moment(props.event.items[0].releaseTime).format(
          `${props.event.items[0].dateFormat}`
        ),
        props.event.items[0].releaseTime
      )
    );

    setTimeZone(
      momentTz
        .tz(
          `${props.event.items[0].releaseTime}`,
          `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
        )
        .format("z")
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBrandingFound(props.brandingFound);
  }, [props.brandingFound]);

  //////////////////// SET CALENDAR, DROPDOWN MENU AND ICON
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      let startTime = null;
      let endTime = null;

      console.log("Count down", props.event.items[0].webcast.countdownTimer);

      if (moment(props.event.items[0].releaseTime).isValid()) {
        startTime = moment(props.event.items[0].releaseTime).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
        setEventStartTime(momentTz.tz( `${props.event.items[0].releaseTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())
      } else {
        startTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        setEventStartTime(momentTz.tz( `${props.event.items[0].releaseTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())

      }

      if (props.event.items[0].endTime) {
        if (moment(props.event.items[0].endTime).isValid()) {
          endTime = moment(props.event.items[0].endTime).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          );
          setEventEndTime(momentTz.tz(`${props.event.items[0].endTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())
        } else {
          endTime = moment(startTime)
            .add(1, "hours")
            .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        }
        setEventEndTime(momentTz.tz(`${props.event.items[0].endTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())

      } else {
        endTime = moment(startTime)
          .add(1, "hours")
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        setEventEndTime(momentTz.tz(`${props.event.items[0].endTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())

      }
        setCalendarEvent({
          title: title,
          description: `${eventDescription} Please join the webinar here: ${baseUrl}${authUrl}`,
          startTime: startTime,
          endTime: endTime,
          location: "BRR Media Webcasting Platform",
        });
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [title, eventDescription]);

  let icon = { "calendar-plus-o": "left" };
  let items = [
    { apple: "Apple Calendar" },
    { google: "Google" },
    { outlook: "Outlook" },
  ];

  // COUNTDOWN
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      if (props.event.items[0].isLive === false) {
        props.countDownCompleted();
      }
      return <span>The Event is due to start</span>;
    } else {
      // Render a countdown
      return (
        <>
          <p className="description">Your webinar is due to start in</p>
          <h1 className="title">
            {days > 0 ? (
              <span>
                {days}
                <span className="dhms">d</span>{" "}
              </span>
            ) : (
              <span></span>
            )}
            {hours}
            <span className="dhms">h</span> {minutes}
            <span className="dhms">m</span> {seconds}
            <span className="dhms">s</span>
          </h1>
        </>
      );
    }
  };

  const classes = useStyles();

  return (
    <>
      <Suspense fallback={<></>}>
        <>
          {props.event.items[0].holdingSlide !== null ? (
            <BackgroundImg
              path={props.event.items[0].holdingSlide.path}
              img={true}
              colour={null}
            />
          ) : props.event.items[0].webcast.colour.background !== null ? (
            <BackgroundImg
              path={null}
              img={false}
              colour={props.event.items[0].webcast.colour.background}
            />
          ) : (
            <></>
          )}
        </>
        <CssBaseline />
        <Container maxWidth="xl" className="mainContainer" align="center" justify="center" >
      <Grid container id="secondRow" alignContent="center" justify="center" spacing={0} direction="column" alignItems="center" style={{ minHeight: "100vh" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.gridItemWidth}>
          <Card className={`${classes.root} ${bgOpacity ? "bgLogoTransparency" : ""}`}>
            <CardActionArea>
              {logoPath && (
                <Logo
                  src={logoPath}
                  title={props.event.items[0].title}
                  logoPadding={logoPadding}
                  backgroundColor="#fff"
                />
              )}
              <CardContent className={classes.mainContent}>
                <p className="title branding-title">
                  {props.event.items[0].title}
                </p>
                {props.event.items[0].holdingVideoUrl?.length > 0 && props.event.items[0].holdingVideoUrl[0] !== "" && (
                  <>
                    <p className="description branding-title-upcoming">
                      Watch the video below to test your video and audio playback.
                    </p>
                    <HoldingVideo srcData={props.event.items[0].holdingVideoUrl[0]} />
                  </>
                )}
                {newDateFormat && timeZone && (
                  <Typography variant="h6" component="h6" className='title'>
                    {moment(props.event.items[0].releaseTime).format(`${props.event.items[0].dateFormat}`)}
                    <span> ({timeZone})</span>
                  </Typography>
                )}
                {(props.event.items[0]?.webcast.zoom.enable==true) &&(
                  <>
                  <p className="description branding-title-upcoming">
                    <br/>
                    Please click the <b>‘Connect to Meeting’ </b> button to join the Q+A session via zoom. 
                </p>
                <p className="description branding-title-upcoming">
                    If you have any issues connecting, please use the details below to dial in via phone. Please note, you must use the phone number given when registering. Unrecognised numbers will be removed from the call.  
                </p>
                <p className="description branding-title-upcoming">
                  <b>Meeting ID:</b> {props.event.items[0].webcast.zoom.meetingId}
                  <br/>
                  <b> Meeting Passcode:</b> {props.event.items[0].webcast.zoom.meetingPass}
                </p>
                <p className="description branding-title-upcoming">
                  <b>Zoom dial-in numbers:</b> <br/><Typography><a href="https://us02web.zoom.us/zoomconference?m=ODI5ODM1MDQwODA.Zs_w35kCHHgykUaKYSltZBodM9WZfcSB" target="_blank">https://us02web.zoom.us/zoomconference?m=ODI5ODM1MDQwODA.Zs_w35kCHHgykUaKYSltZBodM9WZfcSB</a></Typography>
                  <br/>
                  Please include the respective ID and passcode above when dialling in
                </p>
                </>
                )}
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.actionArea}>
              <CardContent className={classes.btnsContainer}>
                <Button
                  className={classes.btnLink}
                  startIcon={<CalendarToday />}
                  variant="contained"
                  size="large"
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickCalendar}
                >
                  Add To Calendar
                </Button>
                {(props.event.items[0].webcast.zoom.enable==true) && (
                  <Button className={classes.btnLink} endIcon={<DoubleArrowIcon />} variant="contained" size="large" onClick={handleMeetingConnect}>
                    Connect to Meeting
                  </Button>
                )}
              </CardContent>
              <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleGenerateZoomCalendarLink(
                      'apple',
                      eventStartTime,
                      eventEndTime,
                      '',
                      `${companyName} - ${title}`,
                      `Click here to join the webinar: ${baseUrl}${authUrl}\n\n${eventDescription}\n\nZoom dial-in numbers: https://us02web.zoom.us/zoomconference?m=ODI5ODM1MDQwODA.Zs_w35kCHHgykUaKYSltZBodM9WZfcSB\n\nPlease include the respective ID and passcode above when dialling in`,
                    );
                    handleClose();
                  }}
                  className={classes.menuItemWithIcons}
                >
                  <BsApple fontSize={'1.2rem'} />
                  <Typography style={{ textAlign: 'center', fontSize: '1rem' }}>Apple</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleGenerateZoomCalendarLink(
                      'google',
                      eventStartTime,
                      eventEndTime,
                      '',
                      `${companyName} - ${title}`,
                      `<a href="${baseUrl}${authUrl}" target="_blank">Click here to join the webinar</a>\n\n${eventDescription}\n\n<b>Zoom dial-in numbers:</b><a href="https://us02web.zoom.us/zoomconference?m=ODI5ODM1MDQwODA.Zs_w35kCHHgykUaKYSltZBodM9WZfcSB" target="_blank">https://us02web.zoom.us/zoomconference?m=ODI5ODM1MDQwODA.Zs_w35kCHHgykUaKYSltZBodM9WZfcSB</a>\n\n<em>Please include the respective ID and passcode above when dialling in</em>`
                    );
                    handleClose();
                  }}
                  className={classes.menuItemWithIcons}
                >
                  <BsGoogle fontSize={'1.2rem'} />
                  <Typography style={{ textAlign: 'center', fontSize: '1rem' }}>Google</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleGenerateZoomCalendarLink(
                      'outlook',
                      eventStartTime,
                      eventEndTime,
                      '',
                      `${companyName} - ${title}`,
                      `Click here to join the webinar: ${baseUrl}${authUrl}\n\n${eventDescription}\n\nZoom dial-in numbers: https://us02web.zoom.us/zoomconference?m=ODI5ODM1MDQwODA.Zs_w35kCHHgykUaKYSltZBodM9WZfcSB\n\nPlease include the respective ID and passcode above when dialling in`,
                    );
                    handleClose();
                  }}
                  className={classes.menuItemWithIcons}
                >
                  <BsMicrosoft fontSize={'1.2rem'} />
                  <Typography style={{ textAlign: 'center', fontSize: '1rem' }}>Outlook</Typography>
                </MenuItem>
              </Menu>
              {props.event.items[0].webcast.countdownTimer && (
                <Typography component="div" className={classes.countdown}>
                  <Countdown date={Date.now() + props.timeToEvent} renderer={renderer} zeroPadTime={2} />
                </Typography>
              )}
              <div className="share-btns-section">
                {shareBtns && (
                  <ShareButtons eventID={eventID} title={title} description={eventDescription} companyName={companyName} />
                )}
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
        {!props.match.location.search.includes("template=clean") && ( !props.event.items[0].registrationRequired) &&  (

          <CookieConsent
          enableDeclineButton
          buttonText="Agree"
          declineButtonText="Disagree"
          onAccept={()=>{
            setGA(true)
          }}
          onDecline={() => {
            alert("nay!");
          }}

            cookieName="mySiteCookieConsent"
            location="top"
            style={{ background: "#e3e3e3", color: "#2B373B", zIndex: 99000 }}
          >
            We use cookies to provide and improve our services. By using our
            site you consent to{" "}
            <a href="../../../../../cookies" target="_blank">
              cookies
            </a>
          </CookieConsent>

        )}
      </Suspense>
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps === nextProps;
};

export default React.memo(ZoomEvent, areEqual);