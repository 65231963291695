import React, { useState, useEffect, useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { ASSETS_BASE_URL } from '../../config/config';

// MUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { gaEvent } from '../../helpers/reactGA';

// COMPONENTS
import PresenterSocialMediaLink from './PresenterSocialMediaLink';

// IMG
import logoPlaceholder from '../../static/logo-placeholder100.png';

// CONTEXT 
import WebcastingContext from '../../context/webcasting/webcastingContext';


import './index.css';

const theme = createMuiTheme();

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '24px',
    [theme.breakpoints.down('400')]: {
      padding: '16px',
    },
  },
  logo: {
    objectFit: 'contain',
    maxWidth: '100%',
    height: 'auto',
  },
  listSpeakers: {
    width: '100%',
    maxWidth: '75ch',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItemTextNameOnly: {
    alignSelf: 'center',
  },
  listItemBtn: {
    width: 'max-content',
    marginTop: '0.6rem',
    textTransform: 'capitalize',
    color: 'var(--clr-g-3)',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'var(--clr-g-3)',
      color: 'var(--clr-g-f7)',
    },
  },
  link: {
    padding: '8px 0px',
  },
  inline: {
    display: 'inline',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  avatarBackgroundColor: {
    color: '#ededed',
    backgroundColor: '#333',
  },
  collapseContainer: {
    maxWidth: '58ch',
  },
  closeBtn: {
    left: 'calc(100% - 40px)',
    padding: '8px',
  },
  socialLinkContainer: {
    marginTop: '15px',
  },
}));

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

theme.typography.h4 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

function isLogoTypeWebsite(logo) {
  return logo.type === 'website';
}

const InfoDrawer = ({ event, drawerInfoState, onClose, socket_wc }) => {

  const webcastingContext = useContext(WebcastingContext);
  const {branding, authorization, noRegID} = webcastingContext;



  const [infoState, setInfoState] = useState(drawerInfoState);

  const [openBio, setOpenBio] = useState({});
  let logoPath = '';

  if (event.companies[0].logos.find(isLogoTypeWebsite) === undefined) {
    logoPath = null;
  } else {
    logoPath = event.companies[0].logos.find(isLogoTypeWebsite).upload.path;
  }

  let websiteExists = false;
  let websiteValue = '';
  let websiteDisplayed = '';
  if (event.companies[0]) {
    if (event.companies[0].websites[0]) {
      if (event.companies[0].websites[0].value) {
        websiteExists = true;
        websiteValue = event.companies[0].websites[0].value;
        websiteDisplayed = event.companies[0].websites[0].value
          .replace(/^https?:\/\//g, '')
          .split('/')[0];
      }
    }
  }

  useEffect(() => {
    if(event.registrationRequired) { 
      let action = {
        userID:Object.entries(authorization).length > 0 && authorization.registrants[0].findId,
        action: Object.entries(authorization).length > 0 &&  JSON.parse(authorization.registrants[0].fields).email + ' clicked info tab',
        eventID:event._id,
        type:'INFO',
        time:new Date(),
        details:{      
        }
  }
  Object.entries(authorization).length > 0 && socket_wc.emit('userAction',action); 
    } else {
      let action = {
        userID:noRegID.length > 0 && noRegID,
        action: noRegID.length > 0 &&  noRegID + ' clicked info tab',
        eventID:event._id,
        type:'INFO',
        time:new Date(),
        details:{      
        }
  }
socket_wc.emit('userAction',action); 
    }


  }, [authorization, noRegID]);


  const toggleDrawer = (anchor, open) => (event) => {

    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setInfoState({ ...infoState, [anchor]: open });
  };

  const handlerWebsiteLink = () => {
    gaEvent('info_drawer', 'click', 'openUrl');
  };

  const handleOpenBio = (id) => {
    setOpenBio({ ...openBio, [id]: !openBio[id] });
  };

  const classes = useStyles();

  return (
    <div
      role='presentation'
      onClick={toggleDrawer(`left`, false)}
      onKeyDown={toggleDrawer(`left`, false)}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.mainContainer}>
            <IconButton
              aria-label='close'
              disableRipple={true}
              onClick={onClose}
              className={classes.closeBtn}
            >
              <CloseIcon />
            </IconButton>
            <ThemeProvider theme={theme}>
              <Grid item container alignItems='flex-end'>
                <Grid item xs={12}>
                  {logoPath === null ? (
                    <LazyLoadImage
                      src={logoPlaceholder}
                      alt='logo'
                      className={classes.logo}
                      effect='blur'
                      height={'100px'}
                    />
                  ) : (
                    <LazyLoadImage
                      src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${logoPath}`}
                      alt='logo'
                      className={classes.logo}
                      effect='blur'
                      height={'100px'}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1} mb={2}>
                    <h1 className={branding ? 'title' : undefined}>
                      {event.title}
                    </h1>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <p className={branding ? 'description' : undefined}>
                  {event.description.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                    {/* {event.description */}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  {websiteExists && (
                    <Box mt={1}>
                      <Typography variant='caption'>
                        <Link
                          className={classes.link}
                          onClick={handlerWebsiteLink}
                          href={websiteValue}
                          target='_blank'
                          variant='body2'
                          color='primary'
                          rel='noreferrer'
                        >
                          {websiteDisplayed}
                        </Link>
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Box mt={4}>
                <Typography variant='h4'>Speakers:</Typography>
              </Box>
              <Box>
                <List className={classes.listSpeakers}>
                  <Divider variant='fullWidth' component='li' />
                  {event.presenters.map((presenter, idx) => (
                    <div key={idx}>
                      <ListItem
                        className={classes.listItem}
                        key={presenter.name}
                        alignItems='flex-start'
                      >
                        <ListItemAvatar>
                          {presenter.images[0] ? (
                            <div className={classes.avatar}>
                              <LazyLoadImage
                                src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${presenter.images[0].upload.path}`}
                                alt={presenter.name}
                                effect='blur'
                                height={'100%'}
                                delayTime={600}
                              />
                            </div>
                          ) : (
                            <Avatar
                              alt={presenter.name}
                              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/`}
                              className={classes.avatarBackgroundColor}
                            />
                          )}
                        </ListItemAvatar>
                        <Box className={classes.listItemText}>
                          {presenter.occupations[0].position !== '   ' ? (
                            <ListItemText 
                              classes={{primary:'title',secondary:'description'}}
                              primary={presenter.name}
                              secondary={presenter.occupations[0].position}
                            />
                          ) : (
                            <ListItemText
                              className={classes.listItemTextNameOnly}
                              primary={presenter.name}
                            />
                          )}
                          {presenter.description &&
                            presenter.description.length > 2 && (
                              <>
                                <Button
                                  onClick={() => handleOpenBio(idx)}
                                  className={classes.listItemBtn}
                                  variant='contained'
                                >
                                  {openBio[idx] ? 'Close' : 'Bio'}
                                </Button>
                                <Collapse
                                  in={openBio[idx]}
                                  className={classes.collapseContainer}
                                >
                                  <Typography variant='body2' className='description'>
                                    {/* {presenter.description} */}
                                  {presenter.description.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                  </Typography>
                                </Collapse>
                              </>
                            )}
                          {presenter.websites.length > 0 && (
                            <Grid
                              container
                              spacing={1}
                              className={classes.socialLinkContainer}
                            >
                              {presenter.websites.map((website) => (
                                <Grid item key={website._id}>
                                  <PresenterSocialMediaLink
                                    value={website.value}
                                    type={website.type}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </Box>
                      </ListItem>
                      <Divider variant='fullWidth' component='li' />
                    </div>
                  ))}
                </List>
              </Box>
            </ThemeProvider>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default InfoDrawer;
